import { useCallback } from 'react';
import type { NextPage } from 'next';
import Image from 'next/image';
import { getTextLastChars } from '../../shared/utilities/text';
import ShinyButton from '../Buttons/ShinyButton';
import styles from './MobileWalletAuth.module.css';
import ConnectedAnalImage from '../../assets/connected-anal.png';
import { useAppContext } from '../../shared/context/App.context';
import { useUserContext } from '../../shared/context/User.context';
import { ShinyButtonColorEnum } from '../../shared/consts/shinyButtonColors';

const MobileWalletAuth: NextPage = () => {
  const { currentAddress, isUserConnected } = useUserContext();
  const { setDisplayUserAuth, setIsWalletInfoModalOpen } = useAppContext();

  const handleConnectClick = useCallback(() => setDisplayUserAuth(true), [setDisplayUserAuth]);

  const handleIconClick = useCallback(() => {
    setIsWalletInfoModalOpen(true);
  }, [setIsWalletInfoModalOpen]);

  if (isUserConnected) {
    return (
      <div className={styles.root}>
        <div className={styles.walletWrapper}>
          <Image src={ConnectedAnalImage} alt="ConnectedAnal" width={40} height={32} onClick={handleIconClick} />
        </div>
        <span>{getTextLastChars(currentAddress)}</span>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <ShinyButton
        small
        dense
        color={ShinyButtonColorEnum.silver}
        onClick={handleConnectClick}
        className={styles.connectButton}
      >
        Connect
      </ShinyButton>
    </div>
  );
};

export default MobileWalletAuth;
