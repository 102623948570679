import Link from 'next/link';
import cx from 'classnames';
import Image from 'next/image';
import { useCallback } from 'react';
import styles from './Footer.module.css';
import config from '../../config';
import CaretRight from '../../assets/icons/caret-right.svg';
import FaqSectionsEnum from '../../shared/consts/faqSectionsEnum';
import BottomRocket from '../../assets/bottomRocket.png';
import TelegramIcon from '../../assets/telegram-icon.svg';
import XIcon from '../../assets/x-icon.svg';

type FooterColumnItem = {
  itemName: string;
  itemLink: string;
};

type FooterColumnType = {
  title: string;
  titleLink?: string;
  items: FooterColumnItem[];
};

const Footer = () => {
  const footerMenu: FooterColumnType[] = [
    {
      title: 'FAQ',
      titleLink: config.routes.faq,
      items: [
        {
          itemName: 'How to get $MUSK?',
          itemLink: `${config.routes.faq}#${FaqSectionsEnum.General}`,
        },
        {
          itemName: 'Wallet',
          itemLink: `${config.routes.faq}#${FaqSectionsEnum.Wallet}`,
        },
      ],
    },
  ];

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <footer className={styles.root}>
      <div className={styles.bottomRocketWrp}>
        <Image width={250} objectFit="contain" className={styles.bottomRocket} src={BottomRocket} alt="Back to top" />
        <button type="button" className={cx([styles.backToTop, 'shadow-text-gray-left'])} onClick={scrollToTop}>
          BACK TO TOP
        </button>
      </div>
      <div className={styles.container}>
        {footerMenu &&
          footerMenu.map((footerColumn: FooterColumnType) => (
            <div className={styles.footerColumn} key={footerColumn.title}>
              <strong>
                {footerColumn.titleLink ? (
                  <Link href={footerColumn.titleLink}>{footerColumn.title}</Link>
                ) : (
                  footerColumn.title
                )}
              </strong>
              <ul>
                {footerColumn.items.map((menu: FooterColumnItem) => (
                  <li key={menu.itemName + menu.itemLink}>
                    <Link href={menu.itemLink}>
                      <a href={menu.itemLink}>
                        <CaretRight width={6} height={8} fill="#808080" /> {menu.itemName}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
              <div className={styles.iconsWrapper}>
                <a href={config.routes.twitter} target="_blank" className={styles.followLink} rel="noreferrer">
                  <XIcon />
                </a>
                <a
                  href={config.routes.telegram}
                  target="_blank"
                  className={cx(styles.followLink, styles.telegramLink)}
                  rel="noreferrer"
                >
                  <TelegramIcon />
                </a>
              </div>
            </div>
          ))}
        <p className={styles.copyrights}>&copy; {new Date().getFullYear()} AlanMusk</p>
        <a href={`mailto:${config.contactMail}`} className={styles.contact}>
          {config.contactMail}
        </a>
        <div className={styles.disclaimer}>
          $MUSK is a meme coin similar to Bitcoin - with no intrinsic value or expectation of financial return. There is
          no formal team or roadmap. The coin is completely useless and for entertainment purposes only.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
