enum PageTitleEnum {
  HOME = 'HOME',
  EARNINGS = 'EARNINGS',
  NFTS = 'NFTS',
  LEADERBOARD = 'LEADERBOARD',
  FAQ = 'FAQ',
  ROADMAP = 'ROADMAP',
  TOKENOMICS = 'TOKENOMICS',
  LITEPAPER = 'LITEPAPER',
  GAME = 'GAME',
  TERMS = 'TERMS',
  STAKING = 'STAKING',
  BUY = 'BUY',
}

export default PageTitleEnum;
