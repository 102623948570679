import { ReactElement, useCallback } from 'react';
import cx from 'classnames';
import Logo from '../Logo/Logo';
import NavLinks from '../NavLinks/NavLinks';
import styles from './Headers.module.css';
import IconButton from '../Buttons/IconButton';
import MenuIcon from '../../assets/icons/menu.svg';
import { useAppContext } from '../../shared/context/App.context';

import DesktopWalletAuth from '../WalletAuth/DesktopWalletAuth';
import MobileWalletAuth from '../WalletAuth/MobileWalletAuth';

const Headers = (): ReactElement => {
  const { setIsDrawerOpen } = useAppContext();

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, [setIsDrawerOpen]);

  const headerText = '';

  return (
    <>
      <div className={styles.desktopHeaderSpace} />
      <div className={styles.desktopHeader}>
        <Logo small className={styles.logo} />
        <NavLinks />
        <DesktopWalletAuth />
        {headerText && <div className={cx(styles.banner, styles.desktopBanner)}>{headerText}</div>}
      </div>

      {headerText && <div className={cx(styles.banner, styles.mobileBanner)}>{headerText}</div>}
      <div className={styles.mobileHeader}>
        <IconButton onClick={openDrawer}>
          <MenuIcon className={styles.menuIcon} />
        </IconButton>
        <div className={styles.logoArea}>
          <Logo />
        </div>
        <div className={styles.walletArea}>
          <MobileWalletAuth />
        </div>
      </div>
    </>
  );
};

export default Headers;
