import Head from 'next/head';
import { ReactElement } from 'react';
import config from '../../config';

type HeadTitleProps = {
  title: string;
};

export default function HeadTitle({ title }: HeadTitleProps): ReactElement {
  return (
    <Head>
      <title>{config.appPageTitleFormat.replace(':pageTitle', title)}</title>
    </Head>
  );
}
