import { ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import styles from './Title.module.css';

type TitleProps = {
  children: ReactNode;
  big?: boolean;
  className?: string;
};

const Title = ({ children, big, className }: TitleProps): ReactElement => {
  return (
    <div className={cx('shadow-text-gray-left', styles.title, big && styles.big, className && className)}>
      {children}
    </div>
  );
};

export default Title;
