import type { NextPage } from 'next';
import { useCallback, useState } from 'react';
import cx from 'classnames';
import { truncateTextMiddle } from '../../shared/utilities/text';
import ShinyButton from '../Buttons/ShinyButton';
import styles from './DesktopWalletAuth.module.css';
import ArrowDownIcon from '../../assets/icons/arrow-down.svg';
import { useAppContext } from '../../shared/context/App.context';
import { useUserContext } from '../../shared/context/User.context';
import { ShinyButtonColorEnum } from '../../shared/consts/shinyButtonColors';

const DesktopWalletAuth: NextPage = () => {
  const { disconnect, currentAddress, isUserConnected } = useUserContext();
  const [showOptions, setShowOptions] = useState(false);
  const { setDisplayUserAuth } = useAppContext();

  const handleMoreClick = useCallback(() => {
    setShowOptions((x) => !x);
  }, []);

  const handleDisconnectClick = useCallback(() => {
    setShowOptions((x) => !x);
    disconnect();
  }, [disconnect]);

  const handleConnectClick = useCallback(() => setDisplayUserAuth(true), [setDisplayUserAuth]);

  if (isUserConnected) {
    return (
      <div className={styles.root}>
        <button type="button" className={styles.walletInfo} onClick={handleMoreClick}>
          <span>{truncateTextMiddle(currentAddress)}</span>
          <ArrowDownIcon className={cx(styles.moreButton, showOptions && styles.moreButtonOpen)} />
        </button>
        {showOptions && (
          <div className={styles.disconnectButton} onClick={handleDisconnectClick} aria-hidden="true">
            <span>Disconnect</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <ShinyButton
      dense
      color={ShinyButtonColorEnum.silver}
      onClick={handleConnectClick}
      className={styles.connectButton}
    >
      CONNECT WALLET
    </ShinyButton>
  );
};

export default DesktopWalletAuth;
